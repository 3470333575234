var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"accueil-drawer",attrs:{"rail":_vm.rail,"permanent":""}},[_c('Accueil')],1),_c('div',{staticClass:"\n      container-small\n      d-flex\n      flex-column\n      justify-center\n      align-center\n      photo-capture-container\n    "},[_c('div',{staticClass:"photo-status"},[_c('h2',{staticClass:"text-center mb-5",style:({
          color: _vm.WEBAPPPRO_TEXT_COLOR_4,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
          fontWeight: 700,
          marginTop: '10px',
        })},[_vm._v(" Paramétrage ")]),_c('div',{staticClass:"d-flex flex-row flex-wrap mt-10 ma-5"},[_c('div',[_c('v-card',{staticClass:"text-center px-7",style:({
              borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
              paddingTop: '20px',
            }),attrs:{"width":"357","height":"120"}},[_c('div',{staticClass:"row",attrs:{"align":"center"}},[_c('div',{staticClass:"col d-flex text-left",style:({
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  paddingTop: '20px',
                  fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                  fontWeight: 600,
                })},[_c('p',[_vm._v("Vous êtes revendeur "),_c('br'),_vm._v("de code ePhoto")])]),_c('div',{staticClass:"col d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","color":_vm.reseller ? _vm.WEBAPPPRO_TEXT_COLOR_1 : ''},model:{value:(_vm.reseller),callback:function ($$v) {_vm.reseller=$$v},expression:"reseller"}})],1)])])],1),_c('div',{staticClass:"text-left ma-5"},[_c('p',{staticClass:"mb-1",style:({
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              fontWeight: 600,
              paddingLeft: '10px',
            })},[_vm._v(" Recevoir mes emails sur ")]),_c('v-text-field',{staticClass:"rounded-bottom ma-2",style:({ borderRadius: '8px', width: '400px' }),attrs:{"placeholder":_vm.cleanEmail,"solo":""},model:{value:(_vm.emailRedirection),callback:function ($$v) {_vm.emailRedirection=$$v},expression:"emailRedirection"}})],1)]),_c('div',{staticClass:"d-flex flex-row flex-wrap mt-10 ma-5"},_vm._l((_vm.itemsEmail),function(item,i){return _c('MiniCards3Checkbox',{key:i,staticClass:"ma-5",attrs:{"title":item.title,"description":item.description,"titleX1":item.titleX1,"titleX2":item.titleX2,"checkboxX1Y1Title":"Oui","checkboxX1Y2Title":"Non","checkboxX1Y3Title":'Par défaut (' +
            (_vm.partnerPlanDefaultConfig.includes(item.typeX1) ? 'Oui)' : 'Non)'),"checkboxX2Y1Title":"Oui","checkboxX2Y2Title":"Non","checkboxX2Y3Title":'Par défaut (' +
            (_vm.partnerPlanDefaultConfig.includes(item.typeX2) ? 'Oui)' : 'Non)'),"checkboxX1Y1Value":"Oui","checkboxX1Y2Value":"Non","checkboxX1Y3Value":"Default","checkboxX2Y1Value":"Oui","checkboxX2Y2Value":"Non","checkboxX2Y3Value":"Default","radioValueX1":item.valX1 != null ? item.valX1 : _vm.findEmailConfig(item.typeX1),"radioValueX2":item.valX2 != null ? item.valX2 : _vm.findEmailConfig(item.typeX2),"updateX1":function (rValue) {
              item.valX1 = rValue
            },"updateX2":function (rValue) {
              item.valX2 = rValue
            }}})}),1),_c('div',{staticClass:"row d-flex justify-center"},[_c('v-btn',{staticClass:"mb-5 mt-7",style:({
            color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
            borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
            textTransform: 'none',
          }),attrs:{"width":"240px","height":"52px","color":_vm.WEBAPPPRO_BUTTON_COLOR_1},on:{"click":function($event){return _vm.updateReseller()}}},[_vm._v("Enregistrer ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }