<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!-- <div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        v-if="goBack"
        @click="goBackAction()"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div> -->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <div
      class="
        container-small
        d-flex
        flex-column
        justify-center
        align-center
        photo-capture-container
      "
    >
      <div class="photo-status">
        <h2
          class="text-center mb-5"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Prise de photo en attente
        </h2>
        <p
          class="text-center"
          :style="{
            // color: WEBAPPPRO_TEXT_COLOR_2,
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_FONT_SIZE_3,
            fontWeight: 400,
            marginTop: '10px',
          }"
        >
          Retrouvez ici la liste des candidats ayant bien reçu le lien <br />
          mais n’ayant pas encore effectué sa photo.
        </p>
      </div>
      <v-card
        class="pa-6"
        :style="{ width: '735px', borderRadius: WEBAPPPRO_CARD_BORDER_ROUND }"
      >
        <v-data-table
          :headers="headers"
          :items="
            items.map(item => ({
              ...item,
              createdAt: formatCreatedAt(item.createdAt),
            }))
          "
          :items-per-page="10"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.createdAt }}
            </td>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.email }}
            </td>
          </template>
          <template v-slot:[`item.lastName`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.lastName }}
            </td>
          </template>
          <template v-slot:[`item.firstName`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.firstName }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td
              class="d-flex bold-text"
              :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }"
            >
              <v-tooltip top>
                <!-- Déclencheur du tooltip (SVG) -->
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <svg
                      class="mr-2"
                      @click="viewQrCode(item)"
                      :style="{ cursor: 'pointer' }"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="32"
                        height="32"
                        rx="6"
                        :fill="WEBAPPPRO_BUTTON_COLOR_1"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M20.9402 10.4773C20.8716 10.411 20.7922 10.365 20.7059 10.3393C19.3013 9.54022 17.6988 9 16 9C11.0853 9 6 14.5607 6 16.4884C6 18.4354 9.40234 23 16 23C22.1194 23 26 18.4213 26 16.4884C26 15.2518 23.9552 12.2832 20.9402 10.4773ZM20.1443 11.4562C20.6383 12.3088 20.901 13.2897 20.901 14.306C20.901 17.2554 18.7024 19.6547 16.0001 19.6547C13.2977 19.6547 11.0992 17.2552 11.0992 14.306C11.0992 13.3881 11.3168 12.4906 11.7236 11.6968C13.0458 10.8742 14.53 10.2826 16.0001 10.2826C17.5165 10.2836 18.92 10.7629 20.1443 11.4562ZM15.9999 21.7152C9.9119 21.7152 7.17627 17.5602 7.17627 16.4883C7.19196 15.9406 8.35348 14.3467 10.0589 12.912C9.96974 13.3677 9.92269 13.8352 9.92269 14.3059C9.92269 17.9633 12.6485 20.9385 15.9999 20.9385C19.3513 20.9385 22.0772 17.9636 22.0772 14.3059C22.0772 13.7485 22.0105 13.1998 21.887 12.6692C23.6994 14.1807 24.8158 15.9492 24.8236 16.487C24.8236 17.3707 21.8614 21.7152 15.9999 21.7152Z"
                        :fill="WEBAPPPRO_BUTTON_COLOR_1"
                      />
                    </svg>
                  </div>
                </template>

                <!-- Texte du tooltip -->
                <span>Voir le QR code</span>
              </v-tooltip>
              <v-tooltip top>
                <!-- Déclencheur du tooltip (SVG) -->
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <svg
                      class="mr-2"
                      @click="resendQrCode(item.uid)"
                      :style="{ cursor: 'pointer' }"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="32"
                        height="32"
                        rx="6"
                        :fill="WEBAPPPRO_BUTTON_COLOR_1"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M22.9517 12.7866C22.9527 12.7834 22.9538 12.7801 22.9548 12.7769C22.9832 12.7034 23 12.6245 23 12.5401C23 12.4569 22.9832 12.3769 22.9548 12.3034C22.9538 12.3001 22.9527 12.2969 22.9517 12.2936C22.9191 12.2136 22.8728 12.1412 22.814 12.0807L18.0586 7.18973C17.8127 6.93676 17.4134 6.93676 17.1675 7.18973C16.9216 7.44271 16.9216 7.85352 17.1675 8.10651L20.8467 11.8915H14.6749C11.5453 11.8915 9 14.5099 9 17.7295V24.3513C9 24.7092 9.2827 25 9.63055 25C9.97841 25 10.2611 24.7092 10.2611 24.3513V17.7295C10.2611 15.2257 12.241 13.189 14.6748 13.189H20.8458L17.1666 16.974C16.9206 17.2269 16.9206 17.6377 17.1666 17.8907C17.2895 18.0172 17.4513 18.081 17.6121 18.081C17.7729 18.081 17.9347 18.0172 18.0577 17.8907L22.813 12.9987C22.873 12.9392 22.9191 12.8677 22.9517 12.7866Z"
                        :fill="WEBAPPPRO_BUTTON_COLOR_1"
                      />
                    </svg>
                  </div>
                </template>

                <!-- Texte du tooltip -->
                <span>Renvoyer le qrCode à l'élève</span>
              </v-tooltip>
              <v-tooltip top>
                <!-- Déclencheur du tooltip (SVG) -->
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <svg
                      class="mr-1"
                      @click="deleteItem(item)"
                      :style="{ cursor: 'pointer' }"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="32"
                        height="32"
                        rx="6"
                        :fill="WEBAPPPRO_BUTTON_COLOR_1"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M23.4855 9.9715H19.2897C19.0417 8.28982 17.6964 7 16.0738 7C14.4513 7 13.1059 8.28982 12.8579 9.9715H8.51481C8.23081 9.9715 8 10.2213 8 10.5287C8 10.836 8.23081 11.0858 8.51481 11.0858H9.67742L10.2686 23.0657C10.3218 24.1503 11.1497 25 12.1536 25H19.8464C20.8503 25 21.6774 24.1503 21.7314 23.0657L22.3226 11.0858H23.4852C23.7692 11.0858 24 10.836 24 10.5287C24 10.2213 23.7692 9.9715 23.4852 9.9715H23.4855ZM16.074 8.11431C17.1268 8.11431 18.0088 8.90825 18.2422 9.9715H13.9059C14.1393 8.90918 15.0221 8.11431 16.074 8.11431ZM20.7038 23.0063C20.6798 23.4994 20.3031 23.8857 19.8475 23.8857H12.1539C11.6974 23.8857 11.3216 23.4994 11.2976 23.0063L10.709 11.0858H21.2917L20.7038 23.0063ZM17.7634 22.4956C17.7711 22.4956 17.7788 22.4965 17.7857 22.4965C18.0602 22.4965 18.2876 22.2625 18.2996 21.9635L18.6428 13.3461C18.6548 13.0388 18.4343 12.7788 18.1503 12.7658C17.8715 12.75 17.6261 12.9914 17.6141 13.2988L17.2709 21.9161C17.2597 22.2216 17.4794 22.4816 17.7634 22.4956ZM14.0636 22.4956C14.0713 22.4956 14.079 22.4956 14.0859 22.4946C14.3699 22.4816 14.5904 22.2216 14.5784 21.9143L14.2352 13.2969C14.2232 12.9895 13.9812 12.7472 13.6989 12.7639C13.4149 12.7769 13.1944 13.0369 13.2064 13.3443L13.5496 21.9616C13.5608 22.2616 13.789 22.4956 14.0636 22.4956Z"
                        :fill="WEBAPPPRO_BUTTON_COLOR_1"
                      />
                    </svg>
                  </div>
                </template>

                <!-- Texte du tooltip -->
                <span>Supprimer</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="300px">
      <div
        class="mx-auto mt-4 mb-4"
        :style="{
          width: '250px',
          height: '250px',
          border: '2px solid ' + WEBAPPPRO_QRCODE_BORDER_COLOR,
          borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
          boxShadow: WEBAPPPRO_QRCODE_SHADOW,
          padding: '5px',
          backgroundColor: 'white',
        }"
      >
        <v-img :src="qrCodeSrc"></v-img>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from './Accueil.vue'
import { refreshToken } from './RefreshToken.vue'
export default {
  name: 'WaitingOrders',
  components: {
    Accueil,
  },
  data: () => ({
    styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
    buttonTextValidColor: localStorageService.getStyle(
      location.hostname,
      'buttonTextValidColor',
    ),
    WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_ICON_COLOR_INFO',
    ),
    c: localStorageService.getStyle(location.hostname, 'WEBAPPPRO_COLOR_1'),
    WEBAPPPRO_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_2',
    ),
    WEBAPPPRO_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_3',
    ),
    WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_1',
    ),
    WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_2',
    ),
    WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_3',
    ),
    WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_COLOR_1',
    ),
    WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
    ),
    icon: localStorageService.getStyle(location.hostname, 'icon'),
    iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_1',
    ),
    WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_2',
    ),
    WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_1',
    ),
    WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_2',
    ),
    WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_3',
    ),
    WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_3',
    ),
    WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_FONT_SIZE',
    ),
    WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_1',
    ),
    WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_2',
    ),
    background_mobile: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_MOBILE',
    ),
    WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_1',
    ),
    WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_2',
    ),
    WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_3',
    ),
    validatedImages: localStorageService.getValue('validatedImages'),
    addOffer: localStorageService.getValue('addOfferId'),
    WEBAPPPRO_LOGO_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_1',
    ),
    WEBAPPPRO_LOGO_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_2',
    ),
    WEBAPPPRO_CARTE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARTE',
    ),
    WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_COLOR',
    ),
    WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1_FILL_ICON',
    ),
    WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_ETAPES',
    ),
    background_desktop: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_DESKTOP',
    ),
    WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_4',
    ),
    items: [],
    goBack: true,
    headers: [
      { text: 'Prénom', value: 'firstName' },
      { text: 'Nom', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Date de création', value: 'createdAt' },
      { text: '', value: 'actions' },
    ],
    qrCodeSrc: '',
    dialog: false,
  }),
  methods: {
    viewQrCode(item) {
      // Redirection vers la page FicheCandidat avec l'ID du candidat
      this.$router.push({ name: 'FicheCandidat', params: { id: item.id } })

      // Stockage d'informations dans le localStorage
      localStorageService.setObject('firstname', item.firstName)
      localStorageService.setObject('lastname', item.lastName)
      localStorageService.setObject('email', item.email)
      localStorageService.setObject('createdAt', item.createdAt)
      localStorageService.setObject('qrcode', item.uid)

      // requestService
      //   .post(
      //     `/service/authentication_qrcode_request`,
      //     {
      //       user_email: item.email, // Utilise l'email de l'item
      //       partner_email: localStorageService.getValue('partnerEmail'),
      //       partner_admin_uuid: this.$route.params.partner_uid,
      //     },
      //     // {
      //     //   headers: {
      //     //     Authorization: `Bearer ${this.$route.params.partner_uid}`,
      //     //     'Content-Type': 'application/json',
      //     //   }
      //     // }
      //   )
      //   .then(data => {
      //     this.$store.dispatch('alert/success', 'Le qrCode a été bien affiché ')
      //     this.qrCodeSrc = 'data:image/jpeg;base64,' + data.data.qrCode
      //     this.dialog = true // Ouvre le dialog
      //   })
      //   .catch(() => {
      //     this.$store.dispatch(
      //       'alert/error',
      //       "Impossible d'afficher le qrCode.",
      //     )
      //   })
    },
    resendQrCode(uid) {
      try {
        requestService.get(`service/relaunch/${uid}`).then(response => {
          if (response.data.success) {
            this.$store.dispatch(
              'alert/success',
              'Le QR Code a été renvoyé avec succès.',
            )
          } else {
            this.$store.dispatch(
              'alert/error',
              "Le QR Code n'a pas pu être renvoyé.",
            )
          }
        })
      } catch (error) {
        this.$store.dispatch(
          'alert/error',
          "Une erreur s'est produite lors de l'envoi du QR Code.",
        )
      }
    },
    deleteItem(item) {
      if (confirm(`Êtes-vous sûr de vouloir supprimer cet élément ?`)) {
        const index = this.items.findIndex(i => i.id === item.id) // Comparer avec l'email par exemple
        if (index > -1) {
          this.items.splice(index, 1) // Supprime l'élément trouvé
          this.$store.dispatch(
            'alert/success',
            "L'élément a été supprimé avec succès.",
          )
        } else {
          this.$store.dispatch(
            'alert/error',
            "L'élément n'a pas pu être trouvé dans la liste.",
          )
        }
      }
    },
    async handleRefreshToken() {
      try {
        await refreshToken()
      } catch (error) {
        console.error('Erreur lors du rafraîchissement du token:', error)
        // Gérez les erreurs spécifiques ici si nécessaire
      }
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    formatCreatedAt(createdAt) {
      const date = new Date(createdAt)
      // Utilisez les méthodes de Date pour formater la date comme vous le souhaitez
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('fr-FR', options)
    },
  },
  mounted() {
    // Appeler la méthode handleRefreshToken lors du montage du composant
    this.handleRefreshToken()
    var uuid = this.$route.params.partner_uid
    if (uuid !== null) {
      requestService
        .get('/partner/orders/' + uuid + '/waiting')
        .then(response => {
          if (response.data) {
            this.items = response.data
          }
        })
    } else {
      this.$router.push({
        name: 'NotAuthorizedPartner',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    }
    // if (uuid !== undefined && uuid !== null) {
    //   requestService
    //     .get(
    //       '/service/authentication_qrcode_check',
    //       {},
    //       {},
    //       { shortlive_api_key: this.$route.params.id, language: 'fr' },
    //     )
    //     .then(data => {
    //       localStorageService.setObject('qrCodeBase64', data.data.qrCodeBase64)
    //     })
    //     .catch(() => {
    //       console.log('hello')
    //       //this.$router.push({ name: 'ConfirmationEmail' })
    //     })
    //   console.log(localStorageService.getValue(this.$route.params.id))
    // }
  },
}
</script>

<style scoped>
.photo-capture-container {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
}

.photo-icon {
  margin: auto;
}

.photo-status {
  text-align: center;
  margin-bottom: 40px;
}

.text-status {
  text-align: center;
  margin-bottom: 5%;
}

.photo-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.photo-timestamp {
  margin-bottom: 5px;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.bold-text {
  font-weight: bold;
}
</style>
