var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column container"},[_c('div',[(_vm.errors.length)?_c('v-alert',{attrs:{"type":"error"}},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error.id},[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('br'),(_vm.step === 1)?_c('div',[_c('div',{staticClass:"mt-5",staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',{style:({
              color: _vm.WEBAPPPRO_TEXT_COLOR_2,
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              fontWeight: 400,
              marginTop: '225px',
              textAlign: 'center',
            })},[_vm._v("Veuillez renseigner votre email pour recevoir le code de connexion. ")])]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticClass:"d-flex justify-center",style:({
              borderRadius: '8px',
              width: '400px',
              height: '52px',
              marginRight: '30px',
              marginTop: '30px',
            }),attrs:{"density":"dense","placeholder":"Email","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('svg',{staticClass:"ma-2",attrs:{"width":"17","height":"12","viewBox":"0 0 17 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M14.875 0H2.125C0.949167 0 0 1.005 0 2.25V9.75C0 10.995 0.949167 12 2.125 12H14.875C16.0508 12 17 10.995 17 9.75V2.25C17 1.005 16.0508 0 14.875 0ZM14.2092 1.5L8.5 5.13L2.79083 1.5H14.2092ZM14.875 10.5H2.125C1.72833 10.5 1.41667 10.17 1.41667 9.75V2.37L8.13167 6.645C8.35833 6.795 8.64167 6.795 8.85417 6.645L15.5692 2.37V9.75C15.5692 10.17 15.2575 10.5 14.8608 10.5H14.875Z","fill":"#C1C1C1"}})])]},proxy:true}],null,false,160808470),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"d-flex justify-center mt-5"},[_c('v-btn',{style:({
              color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
              borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
              textTransform: 'none',
              marginBottom: '10px',
              marginTop: '10px',
              padding: '0px',
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              width: '240px',
              height: '52px',
            }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1},on:{"click":_vm.submitEmail}},[_vm._v("Recevoir le code")])],1)]):_vm._e(),(_vm.step === 2)?_c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',{style:({
              color: _vm.WEBAPPPRO_TEXT_COLOR_2,
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              fontWeight: 400,
              marginTop: '225px',
            })},[_vm._v("Veuillez saisir le code à 6 chiffres reçu par email. ")])]),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],style:({ color: _vm.WEBAPPPRO_INPUT_TEXT_COLOR }),attrs:{"type":"text","placeholder":"------","maxlength":"6"},domProps:{"value":(_vm.code)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.code=$event.target.value},_vm.formatInput]}})]),_c('div',{staticClass:"d-flex flex-column align-center justify-center mt-3"},[_c('v-btn',{style:({
              color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
              borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
              textTransform: 'none',
              marginBottom: '10px',
              padding: '0px',
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              width: '240px',
              height: '52px',
            }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1},on:{"click":_vm.submitCode}},[_vm._v("Voir les photos disponibles")]),_c('br'),_c('a',{style:({
              color: _vm.WEBAPPPRO_TEXT_COLOR_2,
              textDecoration: 'underline',
              fontWeight: '600',
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
            }),attrs:{"href":"#"},on:{"click":_vm.newCode}},[_vm._v(_vm._s('Renvoyer un nouveau code'))])],1)]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }