var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-center pa-7",style:({
    borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
  }),attrs:{"width":"530"}},[_c('div',{staticClass:"d-flex flex-column",attrs:{"align":"center"}},[_c('div',{staticClass:"col d-flex text-left",style:({
        justifyContent: 'center',
        paddingTop: '5px',
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
        fontWeight: 600,
      })},[_c('p',[_vm._v(_vm._s(_vm.title))])]),_c('p',{style:({
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontWeight: 400,
        fontSize: '14px',
      })},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),(_vm.titleX1)?_c('div',{staticClass:"d-flex justify-space-between mt-2"},[_c('div',{staticClass:"text-left",style:({
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontWeight: 600,
        fontSize: '14px',
        width: '150px',
      })},[_vm._v(" "+_vm._s(_vm.titleX1)+" ")]),_c('label',[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX1Y1Value,"checked":_vm.radioValueX1 === _vm.checkboxX1Y1Value},on:{"change":function($event){return _vm.updateValueX1(_vm.checkboxX1Y1Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX1Y1Title)+" ")]),_c('label',[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX1Y2Value,"checked":_vm.radioValueX1 === _vm.checkboxX1Y2Value},on:{"change":function($event){return _vm.updateValueX1(_vm.checkboxX1Y2Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX1Y2Title)+" ")]),_c('label',[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX1Y3Value,"checked":_vm.radioValueX1 === _vm.checkboxX1Y3Value},on:{"change":function($event){return _vm.updateValueX1(_vm.checkboxX1Y3Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX1Y3Title)+" ")])]):_vm._e(),(_vm.titleX2)?_c('div',{staticClass:"d-flex justify-space-between mt-2"},[_c('div',{staticClass:"text-left",style:({
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontWeight: 600,
        fontSize: '14px',
        width: '150px',
      })},[_vm._v(" "+_vm._s(_vm.titleX2)+" ")]),_c('label',[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX2Y1Value,"checked":_vm.radioValueX2 === _vm.checkboxX2Y1Value},on:{"change":function($event){return _vm.updateValueX2(_vm.checkboxX2Y1Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX2Y1Title)+" ")]),_c('label',[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX2Y2Value,"checked":_vm.radioValueX2 === _vm.checkboxX2Y2Value},on:{"change":function($event){return _vm.updateValueX2(_vm.checkboxX2Y2Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX2Y2Title)+" ")]),_c('label',[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX2Y3Value,"checked":_vm.radioValueX2 === _vm.checkboxX2Y3Value},on:{"change":function($event){return _vm.updateValueX2(_vm.checkboxX2Y3Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX2Y3Title)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }