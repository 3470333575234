var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"accueil-drawer",attrs:{"rail":_vm.rail,"permanent":""}},[_c('Accueil')],1),_c('div',{staticClass:"\n      d-flex\n      flex-column\n      justify-center\n      align-center\n      photo-capture-container\n    "},[_c('div',{staticClass:"main-content-wrapper"},[_c('div',{staticClass:"photo-status"},[_c('h2',{staticClass:"text-center",style:({
            color: _vm.WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          })},[_vm._v(" Suivi de commande ")]),_c('p',{staticClass:"text-center",style:({
            // color: WEBAPPPRO_TEXT_COLOR_2,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
            fontWeight: 400,
            marginTop: '10px',
          })},[_vm._v(" Suivez l’état chaque commande lorsque que le candidat a pris sa photo. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-6",style:({
              width: '100%',
              borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
            })},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.map(function (item) { return (Object.assign({}, item,
                  {createdAt: _vm.formatCreatedAt(item.createdAt)})); }),"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getStatusSvg(item.status))}})]}},{key:"item.id",fn:function(ref){
                var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.firstName",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.firstName)+" ")])]}},{key:"item.lastName",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.lastName)+" ")])]}},{key:"item.email",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.codeEPhoto",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.codeEPhoto)+" ")])]}},{key:"item.method",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.getPaymentMethod(item))+" ")])]}},{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.createdAt)+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('td',{staticClass:"d-flex bold-text",style:({ fontSize: _vm.WEBAPPPRO_FONT_SIZE_3 })},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('svg',{staticClass:"mr-2",style:({ cursor: 'pointer' }),attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.viewQrCode(item)}}},[_c('rect',{attrs:{"width":"32","height":"32","rx":"6","fill":_vm.WEBAPPPRO_BUTTON_COLOR_1,"fill-opacity":"0.1"}}),_c('path',{attrs:{"d":"M20.9402 10.4773C20.8716 10.411 20.7922 10.365 20.7059 10.3393C19.3013 9.54022 17.6988 9 16 9C11.0853 9 6 14.5607 6 16.4884C6 18.4354 9.40234 23 16 23C22.1194 23 26 18.4213 26 16.4884C26 15.2518 23.9552 12.2832 20.9402 10.4773ZM20.1443 11.4562C20.6383 12.3088 20.901 13.2897 20.901 14.306C20.901 17.2554 18.7024 19.6547 16.0001 19.6547C13.2977 19.6547 11.0992 17.2552 11.0992 14.306C11.0992 13.3881 11.3168 12.4906 11.7236 11.6968C13.0458 10.8742 14.53 10.2826 16.0001 10.2826C17.5165 10.2836 18.92 10.7629 20.1443 11.4562ZM15.9999 21.7152C9.9119 21.7152 7.17627 17.5602 7.17627 16.4883C7.19196 15.9406 8.35348 14.3467 10.0589 12.912C9.96974 13.3677 9.92269 13.8352 9.92269 14.3059C9.92269 17.9633 12.6485 20.9385 15.9999 20.9385C19.3513 20.9385 22.0772 17.9636 22.0772 14.3059C22.0772 13.7485 22.0105 13.1998 21.887 12.6692C23.6994 14.1807 24.8158 15.9492 24.8236 16.487C24.8236 17.3707 21.8614 21.7152 15.9999 21.7152Z","fill":_vm.WEBAPPPRO_BUTTON_COLOR_1}})])])]}}],null,true)},[_c('span',[_vm._v("Voir le QR code")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(
                            item.status === 'rejected_photo' ||
                            item.status === 'rejected_signature'
                          )?_c('svg',{style:({ cursor: 'pointer' }),attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.resendQrCode(item.uid)}}},[_c('rect',{attrs:{"width":"32","height":"32","rx":"6","fill":_vm.WEBAPPPRO_BUTTON_COLOR_1,"fill-opacity":"0.1"}}),_c('path',{attrs:{"d":"M22.9517 12.7866C22.9527 12.7834 22.9538 12.7801 22.9548 12.7769C22.9832 12.7034 23 12.6245 23 12.5401C23 12.4569 22.9832 12.3769 22.9548 12.3034C22.9538 12.3001 22.9527 12.2969 22.9517 12.2936C22.9191 12.2136 22.8728 12.1412 22.814 12.0807L18.0586 7.18973C17.8127 6.93676 17.4134 6.93676 17.1675 7.18973C16.9216 7.44271 16.9216 7.85352 17.1675 8.10651L20.8467 11.8915H14.6749C11.5453 11.8915 9 14.5099 9 17.7295V24.3513C9 24.7092 9.2827 25 9.63055 25C9.97841 25 10.2611 24.7092 10.2611 24.3513V17.7295C10.2611 15.2257 12.241 13.189 14.6748 13.189H20.8458L17.1666 16.974C16.9206 17.2269 16.9206 17.6377 17.1666 17.8907C17.2895 18.0172 17.4513 18.081 17.6121 18.081C17.7729 18.081 17.9347 18.0172 18.0577 17.8907L22.813 12.9987C22.873 12.9392 22.9191 12.8677 22.9517 12.7866Z","fill":_vm.WEBAPPPRO_BUTTON_COLOR_1}})]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v("Renvoyer le qrCode à l'élève")])])],1)]}}],null,true)})],1)],1)],1)],1)]),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"mx-auto mt-4 mb-4",style:({
        width: '250px',
        height: '250px',
        border: '2px solid ' + _vm.WEBAPPPRO_QRCODE_BORDER_COLOR,
        borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
        boxShadow: _vm.WEBAPPPRO_QRCODE_SHADOW,
        padding: '5px',
        backgroundColor: 'white',
      })},[_c('v-img',{attrs:{"src":_vm.qrCodeSrc}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }