<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!--<div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        v-if="goBack"
        @click="goBackAction()"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div>-->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <div
      class="
        d-flex
        flex-column
        justify-center
        align-center
        photo-capture-container
      "
    >
      <div class="main-content-wrapper">
        <div class="photo-status">
          <h2
            class="text-center"
            :style="{
              color: WEBAPPPRO_TEXT_COLOR_4,
              fontFamily: WEBAPPPRO_FONT_FAMILY_2,
              fontSize: WEBAPPPRO_FONT_SIZE_2,
              fontWeight: 700,
              marginTop: '10px',
            }"
          >
            Suivi de commande
          </h2>
          <p
            class="text-center"
            :style="{
              // color: WEBAPPPRO_TEXT_COLOR_2,
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_FONT_SIZE_3,
              fontWeight: 400,
              marginTop: '10px',
            }"
          >
            Suivez l’état chaque commande lorsque que le candidat a pris sa
            photo.
          </p>
        </div>
        <v-row>
          <v-col cols="12" md="12" lg="12">
            <v-card
              class="pa-6"
              :style="{
                width: '100%',
                borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
              }"
            >
              <v-data-table
                :headers="headers"
                :items="
                  items.map(item => ({
                    ...item,
                    createdAt: formatCreatedAt(item.createdAt),
                  }))
                "
                :items-per-page="10"
              >
                <!--  <template v-slot:item.status="{ item }">
                <v-icon :color="getStatusColor(item.status)">
                  {{ getStatusIcon(item.status) }}
                </v-icon>
              </template>-->
                <template v-slot:[`item.status`]="{ item }">
                  <div v-html="getStatusSvg(item.status)"></div>
                </template>
                <template v-slot:[`item.id`]="{ item }">
                  <td
                    :style="{
                      fontSize: WEBAPPPRO_FONT_SIZE_3,
                      fontWeight: 400,
                    }"
                  >
                    {{ item.id }}
                  </td>
                </template>
                <template v-slot:[`item.firstName`]="{ item }">
                  <td
                    :style="{
                      fontSize: WEBAPPPRO_FONT_SIZE_3,
                      fontWeight: 400,
                    }"
                  >
                    {{ item.firstName }}
                  </td>
                </template>
                <template v-slot:[`item.lastName`]="{ item }">
                  <td
                    :style="{
                      fontSize: WEBAPPPRO_FONT_SIZE_3,
                      fontWeight: 400,
                    }"
                  >
                    {{ item.lastName }}
                  </td>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <td
                    :style="{
                      fontSize: WEBAPPPRO_FONT_SIZE_3,
                      fontWeight: 400,
                    }"
                  >
                    {{ item.email }}
                  </td>
                </template>
                <template v-slot:[`item.codeEPhoto`]="{ item }">
                  <td
                    :style="{
                      fontSize: WEBAPPPRO_FONT_SIZE_3,
                      fontWeight: 400,
                    }"
                  >
                    {{ item.codeEPhoto }}
                  </td>
                </template>
                <!-- Slot pour l'icône de méthode -->
                <template v-slot:[`item.method`]="{ item }">
                  <v-icon>
                    {{ getPaymentMethod(item) }}
                  </v-icon>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <td
                    :style="{
                      fontSize: WEBAPPPRO_FONT_SIZE_3,
                      fontWeight: 400,
                    }"
                  >
                    {{ item.createdAt }}
                  </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <td
                    class="d-flex bold-text"
                    :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }"
                  >
                    <v-tooltip top>
                      <!-- Déclencheur du tooltip (SVG) -->
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <svg
                            class="mr-2"
                            @click="viewQrCode(item)"
                            :style="{ cursor: 'pointer' }"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="32"
                              height="32"
                              rx="6"
                              :fill="WEBAPPPRO_BUTTON_COLOR_1"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M20.9402 10.4773C20.8716 10.411 20.7922 10.365 20.7059 10.3393C19.3013 9.54022 17.6988 9 16 9C11.0853 9 6 14.5607 6 16.4884C6 18.4354 9.40234 23 16 23C22.1194 23 26 18.4213 26 16.4884C26 15.2518 23.9552 12.2832 20.9402 10.4773ZM20.1443 11.4562C20.6383 12.3088 20.901 13.2897 20.901 14.306C20.901 17.2554 18.7024 19.6547 16.0001 19.6547C13.2977 19.6547 11.0992 17.2552 11.0992 14.306C11.0992 13.3881 11.3168 12.4906 11.7236 11.6968C13.0458 10.8742 14.53 10.2826 16.0001 10.2826C17.5165 10.2836 18.92 10.7629 20.1443 11.4562ZM15.9999 21.7152C9.9119 21.7152 7.17627 17.5602 7.17627 16.4883C7.19196 15.9406 8.35348 14.3467 10.0589 12.912C9.96974 13.3677 9.92269 13.8352 9.92269 14.3059C9.92269 17.9633 12.6485 20.9385 15.9999 20.9385C19.3513 20.9385 22.0772 17.9636 22.0772 14.3059C22.0772 13.7485 22.0105 13.1998 21.887 12.6692C23.6994 14.1807 24.8158 15.9492 24.8236 16.487C24.8236 17.3707 21.8614 21.7152 15.9999 21.7152Z"
                              :fill="WEBAPPPRO_BUTTON_COLOR_1"
                            />
                          </svg>
                        </div>
                      </template>

                      <!-- Texte du tooltip -->
                      <span>Voir le QR code</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <!-- Déclencheur du tooltip (SVG) -->
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <svg
                            v-if="
                              item.status === 'rejected_photo' ||
                              item.status === 'rejected_signature'
                            "
                            @click="resendQrCode(item.uid)"
                            :style="{ cursor: 'pointer' }"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="32"
                              height="32"
                              rx="6"
                              :fill="WEBAPPPRO_BUTTON_COLOR_1"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M22.9517 12.7866C22.9527 12.7834 22.9538 12.7801 22.9548 12.7769C22.9832 12.7034 23 12.6245 23 12.5401C23 12.4569 22.9832 12.3769 22.9548 12.3034C22.9538 12.3001 22.9527 12.2969 22.9517 12.2936C22.9191 12.2136 22.8728 12.1412 22.814 12.0807L18.0586 7.18973C17.8127 6.93676 17.4134 6.93676 17.1675 7.18973C16.9216 7.44271 16.9216 7.85352 17.1675 8.10651L20.8467 11.8915H14.6749C11.5453 11.8915 9 14.5099 9 17.7295V24.3513C9 24.7092 9.2827 25 9.63055 25C9.97841 25 10.2611 24.7092 10.2611 24.3513V17.7295C10.2611 15.2257 12.241 13.189 14.6748 13.189H20.8458L17.1666 16.974C16.9206 17.2269 16.9206 17.6377 17.1666 17.8907C17.2895 18.0172 17.4513 18.081 17.6121 18.081C17.7729 18.081 17.9347 18.0172 18.0577 17.8907L22.813 12.9987C22.873 12.9392 22.9191 12.8677 22.9517 12.7866Z"
                              :fill="WEBAPPPRO_BUTTON_COLOR_1"
                            />
                          </svg>
                        </div>
                      </template>

                      <!-- Texte du tooltip -->
                      <span>Renvoyer le qrCode à l'élève</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="300px">
      <div
        class="mx-auto mt-4 mb-4"
        :style="{
          width: '250px',
          height: '250px',
          border: '2px solid ' + WEBAPPPRO_QRCODE_BORDER_COLOR,
          borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
          boxShadow: WEBAPPPRO_QRCODE_SHADOW,
          padding: '5px',
          backgroundColor: 'white',
        }"
      >
        <v-img :src="qrCodeSrc"></v-img>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from './Accueil.vue'
import { refreshToken } from './RefreshToken.vue'
export default {
  name: 'Orders',
  components: {
    Accueil,
  },
  data: () => ({
    styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
    buttonTextValidColor: localStorageService.getStyle(
      location.hostname,
      'buttonTextValidColor',
    ),
    WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_ICON_COLOR_INFO',
    ),
    WEBAPPPRO_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1',
    ),
    WEBAPPPRO_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_2',
    ),
    WEBAPPPRO_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_3',
    ),
    WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_1',
    ),
    WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_2',
    ),
    WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_3',
    ),
    WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_COLOR_1',
    ),
    WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
    ),
    icon: localStorageService.getStyle(location.hostname, 'icon'),
    iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_1',
    ),
    WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_2',
    ),
    WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_1',
    ),
    WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_2',
    ),
    WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_3',
    ),
    WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_3',
    ),
    WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_FONT_SIZE',
    ),
    WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_1',
    ),
    WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_2',
    ),
    background_mobile: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_MOBILE',
    ),
    WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_1',
    ),
    WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_2',
    ),
    WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_3',
    ),
    validatedImages: localStorageService.getValue('validatedImages'),
    addOffer: localStorageService.getValue('addOfferId'),
    WEBAPPPRO_LOGO_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_1',
    ),
    WEBAPPPRO_LOGO_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_2',
    ),
    WEBAPPPRO_CARTE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARTE',
    ),
    WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_COLOR',
    ),
    WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1_FILL_ICON',
    ),
    WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_ETAPES',
    ),
    background_desktop: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_DESKTOP',
    ),
    WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_4',
    ),
    items: [],
    goBack: true,
    headers: [
      { text: 'Etat', value: 'status' },
      //{ text: '', value: 'agency_payment' },
      { text: 'Commande', value: 'id' },
      { text: 'Prénom', value: 'firstName' },
      { text: 'Nom', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Code ePhoto', value: 'codeEPhoto' },
      { text: '', value: 'method' },
      { text: 'Date de création', value: 'createdAt' },
      { text: '', value: 'actions' },
    ],
    qrCodeSrc: '',
    dialog: false,
    /*items: [
        { status: 'in_progress', method: 'wallet', createdAt: '2024-07-19T12:34:56Z' },
        { status: 'fulfilled', method: 'stripe_card', createdAt: '2024-07-18T11:24:56Z' },
        // autres éléments de données
      ]*/
  }),
  methods: {
    viewQrCode(item) {
      // Redirection vers la page FicheCandidat avec l'ID du candidat
      this.$router.push({ name: 'FicheCandidat', params: { id: item.id } })

      // Stockage d'informations dans le localStorage
      localStorageService.setObject('firstname', item.firstName)
      localStorageService.setObject('lastname', item.lastName)
      localStorageService.setObject('email', item.email)
      localStorageService.setObject('createdAt', item.createdAt)
      localStorageService.setObject('qrcode', item.uid)
    },
    resendQrCode(uid) {
      try {
        requestService.get(`service/relaunch/${uid}`).then(response => {
          if (response.data.success) {
            this.$store.dispatch(
              'alert/success',
              'Le QR Code a été renvoyé avec succès.',
            )
          } else {
            this.$store.dispatch(
              'alert/error',
              "Le QR Code n'a pas pu être renvoyé.",
            )
          }
        })
      } catch (error) {
        this.$store.dispatch(
          'alert/error',
          "Une erreur s'est produite lors de l'envoi du QR Code.",
        )
      }
    },
    async handleRefreshToken() {
      try {
        await refreshToken()
      } catch (error) {
        console.error('Erreur lors du rafraîchissement du token:', error)
        // Gérez les erreurs spécifiques ici si nécessaire
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('fr-FR')
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    formatCreatedAt(createdAt) {
      const date = new Date(createdAt)
      // Utilisez les méthodes de Date pour formater la date comme vous le souhaitez
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('fr-FR', options)
    },
    getStatusSvg(status) {
      switch (status) {
        case 'in_progress':
        case 'pending_payment':
          return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="6" fill="#FF7A23"/>
          <path d="M14.9779 10.2901H17.4816C17.7724 10.2901 18 10.0496 18 9.77107V7.25175C18 6.96057 17.7597 6.73269 17.4816 6.73269C17.2034 6.73269 16.9631 6.97323 16.9631 7.25175V8.49242L16.2171 7.74549C13.8904 5.41607 10.0843 5.41607 7.75764 7.75815C6.6196 8.88488 6 10.3914 6 11.9992C6 13.607 6.6196 15.1009 7.75764 16.2403C8.88303 17.367 10.3878 18 11.9937 18C13.5996 18 15.0917 17.3797 16.2297 16.2403C17.1528 15.3161 17.7471 14.1387 17.9241 12.8348C17.9621 12.5436 17.7597 12.2777 17.4816 12.2397C17.1907 12.1891 16.9252 12.4043 16.8872 12.6828C16.7355 13.7589 16.255 14.7211 15.4837 15.4933C13.6122 17.367 10.3625 17.367 8.49104 15.4933C7.55532 14.5565 7.04953 13.3158 7.04953 11.9992C7.04953 10.6826 7.56797 9.42925 8.49104 8.50508C10.4131 6.58078 13.549 6.58078 15.4837 8.50508L16.2297 9.25201H14.9905C14.6997 9.25201 14.4721 9.49255 14.4721 9.77107C14.4721 10.0496 14.687 10.2901 14.9779 10.2901Z" fill="white" stroke="white" stroke-width="0.6"/>
          </svg>
          `
        case 'fulfilled':
          return `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="6" fill="#47D881"/>
          <path d="M16.0978 7.84636C15.8876 8.06726 15.6797 8.28582 15.4696 8.50672C14.9667 9.03549 14.4637 9.56425 13.9608 10.093C13.3528 10.7322 12.747 11.3691 12.139 12.0083C11.6182 12.5559 11.0951 13.1058 10.5743 13.6533C10.3217 13.9189 10.0647 14.1821 9.81653 14.45C9.80536 14.4618 9.79865 14.4665 9.78524 14.4782C9.86124 14.4171 9.935 14.356 10.011 14.2949C10.0021 14.2996 9.99536 14.302 9.98642 14.3067C10.0758 14.2667 10.1652 14.2268 10.2524 14.1892C10.2435 14.1915 10.2345 14.1915 10.2256 14.1939C10.3239 14.1798 10.4245 14.1657 10.5229 14.1516C10.5139 14.1516 10.505 14.1516 10.4961 14.1516C10.5944 14.1657 10.695 14.1798 10.7934 14.1939C10.7844 14.1915 10.7755 14.1892 10.7665 14.1868C10.856 14.2268 10.9454 14.2667 11.0325 14.3043C11.0236 14.2996 11.0169 14.2949 11.008 14.2926C11.084 14.3537 11.1577 14.4148 11.2337 14.4759C11.0437 14.3161 10.8805 14.1092 10.7084 13.9283C10.3977 13.6016 10.087 13.275 9.7763 12.9483C9.39629 12.5488 9.01853 12.1517 8.63852 11.7521C8.39488 11.496 8.15123 11.2398 7.90981 10.986C7.7064 10.7722 7.40687 10.6429 7.11851 10.6429C6.84357 10.6429 6.51722 10.7698 6.32721 10.986C6.13274 11.2093 5.98745 11.503 6.00086 11.8179C6.01427 12.1305 6.11486 12.4243 6.32721 12.6499C6.90169 13.2538 7.4784 13.8601 8.05287 14.4641C8.48652 14.92 8.92017 15.3759 9.35382 15.8318C9.517 16.0034 9.67794 16.1984 9.87689 16.323C10.3664 16.6285 10.9878 16.5251 11.388 16.1162C11.4058 16.0997 11.4215 16.0809 11.4394 16.0621C12.0384 15.4323 12.6375 14.8025 13.2366 14.1727C14.1553 13.2068 15.0717 12.2433 15.9905 11.2774C16.5426 10.697 17.1014 10.1212 17.6491 9.5337C17.658 9.5243 17.6647 9.51725 17.6736 9.50785C17.8771 9.29399 18 8.97909 18 8.67593C18 8.38687 17.8793 8.04376 17.6736 7.84401C17.4613 7.63956 17.1819 7.4868 16.8823 7.5009C16.5918 7.51735 16.3101 7.62075 16.0978 7.84636Z" fill="white"/>
          </svg>
          `
        case 'rejected_photo':
        case 'rejected_signature':
          return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="6" fill="#D84747"/>
          <path d="M12 13.5C12.8372 13.5 13.5 12.9403 13.5 12.2966V7.20336C13.5 6.53172 12.8023 6 12 6C11.1628 6 10.5 6.5597 10.5 7.20336V12.2687C10.5 12.9403 11.1628 13.5 12 13.5Z" fill="white"/>
          <path d="M12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6716 12.8284 15 12 15C11.1716 15 10.5 15.6716 10.5 16.5C10.5 17.3284 11.1716 18 12 18Z" fill="white"/>
          </svg>
          `
        default:
          return 'mdi-circle-outline'
      }
    },
    /* getStatusColor(status) {
      switch (status) {
        case 'in_progress':
          return 'orange'
        case 'fulfilled':
          return 'green'
        case 'rejected_photo':
        case 'rejected_signture':
          return 'red'
        case 'pending_payment':
          return 'grey'
        default:
          return 'black'
      }
    },*/
    getPaymentMethod(item) {
      if (item.agency_payment === 'true' && item.method === 'wallet') {
        return 'mdi-wallet'
      } else if (item.agency_payment === 'false' && item.method === 'wallet') {
        return 'mdi-bank'
      } else if (item.method === 'stripe_card') {
        return 'mdi-credit-card'
      } else {
        return 'mdi-circle-outline'
      }
    },
  },
  mounted() {
    // Appeler la méthode handleRefreshToken lors du montage du composant
    this.handleRefreshToken()
    var uuid = this.$route.params.partner_uid
    if (uuid !== null) {
      requestService.get('/partner/orders/' + uuid).then(response => {
        if (response.data) {
          this.items = response.data
        }
      })
    } else {
      this.$router.push({
        name: 'NotAuthorizedPartner',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    }
  },
}
</script>

<style scoped>
.photo-capture-container {
  flex-direction: column;
  margin-top: 5%;
}

.photo-icon {
  margin: auto;
  margin-top: 5%;
}

.photo-status {
  text-align: center;
  margin-bottom: 2%;
}

.photo-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.photo-name,
.photo-email,
.photo-timestamp {
  margin-bottom: 5px;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.main-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-left: 100px; /* Largeur du drawer */
}
</style>
