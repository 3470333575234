<template>
  <v-card
    class="text-center pa-7"
    :style="{
      borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
    }"
    width="530"
  >
    <div class="d-flex flex-column" align="center">
      <div
        class="col d-flex text-left"
        :style="{
          justifyContent: 'center',
          paddingTop: '5px',
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontSize: WEBAPPPRO_FONT_SIZE_3,
          fontWeight: 600,
        }"
      >
        <p>{{ title }}</p>
      </div>
      <p
        :style="{
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontWeight: 400,
          fontSize: '14px',
        }"
      >
        {{ description }}
      </p>
    </div>
    <div class="d-flex justify-space-between mt-2" v-if="titleX1">
      <div
        class="text-left"
        :style="{
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontWeight: 600,
          fontSize: '14px',
          width: '150px',
        }"
      >
        {{ titleX1 }}
      </div>
      <label>
        <input
          type="radio"
          :value="checkboxX1Y1Value"
          :checked="radioValueX1 === checkboxX1Y1Value"
          @change="updateValueX1(checkboxX1Y1Value)"
        />
        {{ checkboxX1Y1Title }}
      </label>
      <label>
        <input
          type="radio"
          :value="checkboxX1Y2Value"
          :checked="radioValueX1 === checkboxX1Y2Value"
          @change="updateValueX1(checkboxX1Y2Value)"
        />
        {{ checkboxX1Y2Title }}
      </label>
      <label>
        <input
          type="radio"
          :value="checkboxX1Y3Value"
          :checked="radioValueX1 === checkboxX1Y3Value"
          @change="updateValueX1(checkboxX1Y3Value)"
        />
        {{ checkboxX1Y3Title }}
      </label>
    </div>
    <div class="d-flex justify-space-between mt-2" v-if="titleX2">
      <div
        class="text-left"
        :style="{
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontWeight: 600,
          fontSize: '14px',
          width: '150px',
        }"
      >
        {{ titleX2 }}
      </div>
      <label>
        <input
          type="radio"
          :value="checkboxX2Y1Value"
          :checked="radioValueX2 === checkboxX2Y1Value"
          @change="updateValueX2(checkboxX2Y1Value)"
        />
        {{ checkboxX2Y1Title }}
      </label>
      <label>
        <input
          type="radio"
          :value="checkboxX2Y2Value"
          :checked="radioValueX2 === checkboxX2Y2Value"
          @change="updateValueX2(checkboxX2Y2Value)"
        />
        {{ checkboxX2Y2Title }}
      </label>
      <label>
        <input
          type="radio"
          :value="checkboxX2Y3Value"
          :checked="radioValueX2 === checkboxX2Y3Value"
          @change="updateValueX2(checkboxX2Y3Value)"
        />
        {{ checkboxX2Y3Title }}
      </label>
    </div>
  </v-card>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'MiniCards3Checkbox',
  props: {
    title: String,
    description: String,
    titleX1: String,
    checkboxX1Y1Title: String,
    checkboxX1Y2Title: String,
    checkboxX1Y3Title: String,
    checkboxX1Y1Value: String,
    checkboxX1Y2Value: String,
    checkboxX1Y3Value: String,
    titleX2: String,
    checkboxX2Y1Title: String,
    checkboxX2Y2Title: String,
    checkboxX2Y3Title: String,
    checkboxX2Y1Value: String,
    checkboxX2Y2Value: String,
    checkboxX2Y3Value: String,
    radioValueX1: String,
    updateX1: Function,
    radioValueX2: String,
    updateX2: Function,
  },
  data() {
    return {
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
    }
  },
  methods: {
    updateValueX1(val) {
      this.updateX1(val)
      this.radioValueX1 = val
    },
    updateValueX2(val) {
      this.updateX2(val)
      this.radioValueX2 = val
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.v-application p {
  margin-bottom: 0px;
}
</style>
